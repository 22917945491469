<template>
  <div class="sweepstakes_wrap">
    <h1>扫码关注送礼品</h1>
    <div class="start_content">
      <div class="sweepstakes_title">
        <div class="sweepstakes_title_unpack">感谢您的关注，填写下方信息即可领取礼品</div>
        <div class="sweepstakes_guan">
          <p><span>*</span>您的姓名?</p>
          <input type="text" name="contactName" id="contactName" class="sweepstakes_input" maxlength="20" v-model="contactName"/>
        </div>
        <div class="sweepstakes_guan">
          <p><span>*</span>您的手机号码?</p>
          <input type="text" name="contactTel" id="contactTel" class="sweepstakes_input" maxlength="11" v-model="contactTel"/>
        </div>
        <div class="sweepstakes_guan">
          <p><span>*</span>您的单位名称?</p>
          <input type="text" name="unitName" id="unitName" class="sweepstakes_input" maxlength="50" v-model="unitName"/>
        </div>
<!--        <div class="sweepstakes_guan">-->
<!--          <p>您的所在部门？</p>-->
<!--          <input type="text" name="department" id="department" class="sweepstakes_input" maxlength="50" v-model="department"/>-->
<!--        </div>-->
        <div class="sweepstakes_radio">
          <p><span>*</span>您的所属领域是？</p>
          <label for="unitArea_1">
            <input type="radio" value="标准物质"  id="unitArea_1"  name="unitArea" v-model="unitArea"/>标准物质&emsp;
          </label>
          <label for="unitArea_2">
            <input type="radio" value="微生物"  id="unitArea_2"  name="unitArea" v-model="unitArea"/>微生物
          </label>
        </div>
        <div class="sweepstakes_radio" id="myApp">
          <p><span>*</span>您的经营模式是？（可多选）</p>
          <label for="operateModel_1">
            <input type="checkbox" value="经销代理" id="operateModel_1"   name="operateModel"  v-model="checkedNames"/>经销代理
          </label>
          <label for="operateModel_2">
            <input type="checkbox" value="终端" id="operateModel_2"  name="operateModel"  v-model="checkedNames"/>终端
          </label>
          <label for="operateModel_3">
            <input type="checkbox" value="厂商" id="operateModel_3"  name="operateModel"  v-model="checkedNames"/>厂商
          </label>
        </div>
        <div class="sweepstakes_radio">
          （温馨提示：带<span>*</span>的为必填项）
        </div>

      </div>
    </div>
    <div id="save_user_info" class="submit" @click="saveCollectInfo">
      提交
    </div>
  </div>
</template>
<script lang="ts">

import { defineComponent, ref} from "vue";
import $ from "jquery";
import Toast from "@/components/UI/Toast";
import Modal from "@/components/UI/Modal";
import api from "@/api/axios";
export default defineComponent({
  name: "exhibitionLottery",
  components: {
  },

  setup(){
    const contactName =ref("");
    const contactTel =ref("");
    const unitName =ref("");
    const department =ref("");
    const unitArea =ref("");
    const operateModel =ref("");
    const submitFlag=ref(false);
    const checkedNames = ref<any[]>([]);

    function verificationParameter() {
      if (contactName.value.length < 1) {
        Toast({
          title: "请填写您的姓名",
          type: "warning",
        });
        $("#contactName").focus();
        return false;
      }
      const retel = /^1[3|4|5|6|7|8|9][0-9]\d{4,8}$/;
      if (contactTel.value.length != 11 || !retel.test(contactTel.value)) {
        Toast({
          title: "请输入正确的手机号码",
          type: "warning",
        });
        $("#contactTel").focus();
        return false;
      }
      if (unitName.value.length < 1) {
        Toast({
          title: "请填写您的单位名称",
          type: "warning",
        });
        $("#unitName").focus();
        return false;
      }
      if (unitArea.value.length < 1) {
        Toast({
          title: "请选择您的所属领域",
          type: "warning",
        });
        return false;
      }
      if (checkedNames.value.length <=0) {
        Toast({
          title: "请选择您的经营模式",
          type: "warning",
        });
        return false;
      }
      return true;
    }
    function saveCollectInfo(){
      if(!verificationParameter()){
        return false;
      }
      if(submitFlag.value){
        Toast({
          title: '请勿重复提交',
          type: "error"
        })
        return false;
      }

      const formData = new FormData();
      formData.append("contactName", contactName.value);
      formData.append("contactTel", contactTel.value);
      formData.append("unitName", unitName.value);
      formData.append("unitArea", unitArea.value);
      formData.append("operateModel", checkedNames.value.join(","));
      formData.append("department", department.value);
      api
          .post("/M/exhibition/saveInfo", formData)
          .then((res) => {
            if (res.data.success) {
              submitFlag.value = true;
              Modal({
                title: "提交成功",
                content: res.data.msg,
                optionControl:2,
              });
            } else {
              Modal({
                title: "温馨提示",
                content: res.data.msg,
                optionControl:2,
              });
            }
          })
          .catch(() => {
            // Toast({
            //     title: '未知错误，请联系客服',
            //     type: "success"
            // })
          });
    }
    return {
      saveCollectInfo,
      contactName,
      contactTel,
      unitName,
      unitArea,
      department,
      operateModel,
      checkedNames,
    }
  }
});
</script>


<style lang="scss" scoped>
.sweepstakes_wrap {
  padding: 0.2rem;
  padding-bottom: 50px;
}


.sweepstakes_wrap h1 {
  text-align: center;
  line-height: 30px;
  color: #000;
  font-size: 22px;
}

.sweepstakes_title {
  margin: 0 auto;
  margin-top: 15px;
  width: 96%;
  color: #000000;
  padding-bottom: 0;
}


.sweepstakes_title .sweepstakes_title_unpack,
.Sweepstakes_guize_title {
  font-size: 16px;
  font-weight: bold;
  position: relative;
  padding-left: 10px;
  margin-bottom: 0.2rem;
}

.sweepstakes_title .sweepstakes_title_unpack::after,
.Sweepstakes_guize_title::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 2px;
  width: 3px;
  height: 17.5px;
  background: #df0024;
}

.sweepstakes_guan ,.sweepstakes_radio {
  display: block;
  font-size: 16px;
  line-height: 28px;
  font-weight: 500;
  padding: 5px 0;
  span{
    color: red;
  }
}

.sweepstakes_guan input {
  width: 250px;
}

.sweepstakes_radio label {
  padding-right: 40px;
}
.submit {
  margin-top: 0.6rem;
  display: block;
  width: 100%;
  height: 1.2rem;
  background-color: #d64b4b;
  box-shadow: 0 0.133rem 0.267rem 0 rgba(0, 0, 0, 0.3);
  border-radius: 0.133rem;
  line-height: 1.2rem;
  text-align: center;
  color: #ffffff;
  font-size: 0.4rem;
  /*margin-top: 1rem;*/
}
</style>

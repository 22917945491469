

import { defineComponent, ref} from "vue";
import $ from "jquery";
import Toast from "@/components/UI/Toast";
import Modal from "@/components/UI/Modal";
import api from "@/api/axios";
export default defineComponent({
  name: "exhibitionLottery",
  components: {
  },

  setup(){
    const contactName =ref("");
    const contactTel =ref("");
    const unitName =ref("");
    const department =ref("");
    const unitArea =ref("");
    const operateModel =ref("");
    const submitFlag=ref(false);
    const checkedNames = ref<any[]>([]);

    function verificationParameter() {
      if (contactName.value.length < 1) {
        Toast({
          title: "请填写您的姓名",
          type: "warning",
        });
        $("#contactName").focus();
        return false;
      }
      const retel = /^1[3|4|5|6|7|8|9][0-9]\d{4,8}$/;
      if (contactTel.value.length != 11 || !retel.test(contactTel.value)) {
        Toast({
          title: "请输入正确的手机号码",
          type: "warning",
        });
        $("#contactTel").focus();
        return false;
      }
      if (unitName.value.length < 1) {
        Toast({
          title: "请填写您的单位名称",
          type: "warning",
        });
        $("#unitName").focus();
        return false;
      }
      if (unitArea.value.length < 1) {
        Toast({
          title: "请选择您的所属领域",
          type: "warning",
        });
        return false;
      }
      if (checkedNames.value.length <=0) {
        Toast({
          title: "请选择您的经营模式",
          type: "warning",
        });
        return false;
      }
      return true;
    }
    function saveCollectInfo(){
      if(!verificationParameter()){
        return false;
      }
      if(submitFlag.value){
        Toast({
          title: '请勿重复提交',
          type: "error"
        })
        return false;
      }

      const formData = new FormData();
      formData.append("contactName", contactName.value);
      formData.append("contactTel", contactTel.value);
      formData.append("unitName", unitName.value);
      formData.append("unitArea", unitArea.value);
      formData.append("operateModel", checkedNames.value.join(","));
      formData.append("department", department.value);
      api
          .post("/M/exhibition/saveInfo", formData)
          .then((res) => {
            if (res.data.success) {
              submitFlag.value = true;
              Modal({
                title: "提交成功",
                content: res.data.msg,
                optionControl:2,
              });
            } else {
              Modal({
                title: "温馨提示",
                content: res.data.msg,
                optionControl:2,
              });
            }
          })
          .catch(() => {
            // Toast({
            //     title: '未知错误，请联系客服',
            //     type: "success"
            // })
          });
    }
    return {
      saveCollectInfo,
      contactName,
      contactTel,
      unitName,
      unitArea,
      department,
      operateModel,
      checkedNames,
    }
  }
});
